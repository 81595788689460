@import '../../variable';

.about {
  padding: 15px;
  padding-top: 50px;
  // user-select: none;

  overflow-x: hidden;

  @include sm {
    padding-top: 95px;
  }
  * {
    color: #ddd;
  }

  h1,
  h2,
  h3,
  h4 {
    color: #fff;
    * {
      color: #fff;
    }
  }

  font-size: 14px;
  line-height: 1.6;

  .ad-area {
    margin-top: 25px;
  }

  article + .ad-rea {
    margin-top: 10px;
  }

  .tab-wrap {
    background-color: $themeBg;
    // position: sticky;
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // width: calc(100vw - 12px);
    width: 100%;
    box-sizing: border-box;
    top: -1px;
    left: 0;
    z-index: 2;
    // margin-top: 10px;
    padding: 9px 6px 9px 50px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.07);
    box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.2);
    // overflow-x: auto;

    button {
      flex: 1;
      // background-color: #181921;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 8px 13px;
      border-radius: 3px;
      color: rgba(255, 255, 255, 0.5);
      margin: 4px;
      font-size: 15px;

      @include sm {
        padding: 8px 10px;
        font-size: 14px;
      }
      @include xs {
        font-size: 13px;
        padding: 8px 7px;
      }

      + button {
      }

      &.on {
        background-color: $themColor2;
        border-color: $themColor2;
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  .btn-link {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    padding: 7px 10px;
  }
  article {
    margin-top: 20px;
    + article {
      margin-top: 20px;
    }
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    padding: 20px;

    p {
      margin: 15px 0;
    }
    h3 {
      position: relative;
      margin-bottom: 10px;

      span {
        position: relative;
        z-index: 1;
        font-size: 24px;
        box-shadow: inset 0 -8px rgba($themColor1, 0.6), inset 0 -10px #000;
        display: inline;

        // &::before {
        //   content: '';
        //   width: 100%;
        //   height: 10px;
        //   display: block;
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        //   z-index: -1;
        //   opacity: 0.5;
        //   background-color: $themColor1;
        // }
      }
    }

    h4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      word-break: keep-all;
      font-size: 18px;
      margin-bottom: 10px;
    }

    ul {
      position: relative;
      margin: 10px 0 15px;

      &.list-type1 {
        margin: 10px 0 20px;

        li {
          + li {
            margin-top: 7px;
          }
        }
      }
      li {
        position: relative;
        padding-left: 10px;
        opacity: 0.9;

        &::after {
          content: '';
          width: 4px;
          height: 4px;
          display: inline-block;
          position: absolute;
          top: 9px;
          left: 0;
          background-color: #ccc;
        }
        + li {
          margin-top: 10px;
        }
        ul {
          opacity: 0.8;
          font-size: 13px;
          line-height: 1.4;
          margin: 10px 0;
          li {
            margin-top: 3px;
            &::after {
              width: 4px;
              height: 1px;
            }
            + li {
              margin-top: 5px;
            }
          }
        }
      }
    }
  }

  dl {
    margin-bottom: 15px;
    dt {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 16px;
    }
    dd {
      * {
        opacity: 0.7;
      }

      p {
        opacity: 0.95;
        font-size: 15px;
      }
    }
  }
}

$borderColor: rgba(255, 255, 255, 0.2);
$tbBgColor: rgba(255, 255, 255, 0.05);

.ico-scroll {
  display: flex;
  align-items: center;
  svg {
    font-size: 25px;
    line-height: 1;
    overflow: hidden;
    opacity: 0.3;
    animation: moveScroll 0.5s infinite alternate;

    @keyframes moveScroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-5px);
      }
    }
  }
}
.table-scroll-wrap {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 1000px;
    border-collapse: collapse;

    th,
    td {
      padding: 6px 8px;
      border: 1px solid $borderColor;
    }

    th {
      background-color: $tbBgColor;
    }
  }
}

.cont-copy {
  margin-top: 10px;
  opacity: 0.6;
  font-size: 11px;
  text-align: right;
}
